document.addEventListener('DOMContentLoaded', () => {
  const globalHeader = document.querySelector('.js_global-header');
  const overlay = document.querySelector('.js_global-header-overlay');
  const toggleButtons = document.querySelectorAll('.js_global-header-toggle-button');
  const submenus = document.querySelectorAll('.js_global-header-submenu');
  const sidebarButton = document.querySelector('.js_global-header-sidebar-button');
  const sidebar = document.querySelector('.js_global-header-sidebar');

  let scrollY = 0;
  let isActiveSidebar = false;

  const tunableScrollEvent = () => {
    if (scrollY > 200 && window.scrollY - scrollY >= 0) {
      globalHeader.classList.add('-hide');
    } else {
      globalHeader.classList.remove('-hide');
    }
    scrollY = window.scrollY;
  };

  if (globalHeader ) {
    const stickyAttribute = globalHeader.getAttribute('data-global-header-sticky');
    if (stickyAttribute !== null) {
      let isSticky = JSON.parse(stickyAttribute.toLowerCase());
      if (isSticky) {
        globalHeader.classList.add('-sticky');
        window.addEventListener('scroll', tunableScrollEvent);
      }
    }
  }

  const resetSubmenu = () => {
    submenus.forEach((sub) => {
      sub.classList.remove('-active');
    });
    toggleButtons.forEach((button) => {
      button.classList.remove('-active');
    });
    overlay.classList.remove('-active');
  };

  const inactivateSidebar = () => {
    sidebarButton.classList.remove('-active');
    sidebar.classList.remove('-active');
    overlay.classList.remove('-active');
    document.body.style.overflow = '';
    isActiveSidebar = false;
  };

  const activateSidebar = () => {
    resetSubmenu();
    sidebarButton.classList.add('-active');
    sidebar.classList.add('-active');
    overlay.classList.add('-active');
    if (window.innerWidth < 768) {
      document.body.style.overflow = 'hidden';
    }
    isActiveSidebar = true;
  };

  const toggleSubmenu = (event) => {
    console.log("toggleSubmenu")
    const isActive = event.target.classList.contains('-active');
    inactivateSidebar();
    resetSubmenu();
    if (!isActive) {
      event.target.classList.add('-active');
      const index = Array.from(toggleButtons).findIndex(target => target === event.target);
      submenus[index].classList.add('-active');
      overlay.classList.add('-active');
    }
  };

  const inactivateAll = () => {
    console.log("inactivateAll")
    inactivateSidebar();
    resetSubmenu();
  };

  toggleButtons.forEach((button) => {
    button.addEventListener('click', toggleSubmenu);
  });

  if (overlay) {
    overlay.addEventListener('click', inactivateAll);
  }

  if (sidebarButton && sidebar) {
    sidebarButton.addEventListener('click', () => {
      if (isActiveSidebar) {
        inactivateSidebar();
      } else {
        activateSidebar();
      }
    });
  }
});
